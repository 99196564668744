import { Container } from '@components/ui'
import { useTranslation } from 'next-i18next'

interface FeatureBarProps {
  message: string
}

const FeatureBar: React.FC<FeatureBarProps> = ({ message }) => {
  return (
    <div className="bg-black sticky top-0 h-8 flex items-center z-40">
      <Container className="text-center text-white">
        <p>{message}</p>
      </Container>
    </div>
  )
}

export default FeatureBar
