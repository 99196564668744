import cn from 'classnames'
import { FC } from 'react'
import dynamic from 'next/dynamic'

import { CartProvider } from '@components/cart/context'
import { Navbar, Footer } from '@components/common'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { Button } from '@components/ui'

import s from './Layout.module.css'
import { Cart } from '@components/cart'
import { useTranslation } from 'next-i18next'
import Announcement from '../Announcement'

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'))

const Layout: FC = ({ children }) => {
  const { t } = useTranslation(['common'])
  const { acceptedCookies, onAcceptCookies, onDeclineCookies } =
    useAcceptCookies()

  return (
    <CartProvider>
      <div className={cn(s.root)}>
        <Announcement message={t('common:labels.worldwideShipping')} />
        <Navbar />
        <main className="fit">{children}</main>
        <div>
          <Cart />
        </div>
        <Footer />
        <FeatureBar
          title={t('common:cookies.desc')}
          hide={acceptedCookies}
          action={
            <>
              <Button
                className="whitespace-nowrap"
                variant="ghost"
                size="sm"
                onClick={() => onDeclineCookies()}
              >
                {t('common:cookies.decline')}
              </Button>
              <Button
                className="whitespace-nowrap"
                onClick={() => onAcceptCookies()}
                variant="slim"
              >
                {t('common:cookies.accept')}
              </Button>
            </>
          }
        />
      </div>
    </CartProvider>
  )
}

export default Layout
