import cn from 'classnames'

type MenuProps = {
  className?: string
}

const Menu = ({ className, ...props }: MenuProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(['h-6 w-6', className])}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 12h16m-7 6h7"
      />
    </svg>
  )
}

export default Menu
