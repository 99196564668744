import { FC, MouseEventHandler, useMemo } from 'react'
import OffCanvas from 'react-aria-offcanvas'
import { useCartContext } from '../context'
import s from './Cart.module.css'
import { retrieveCart } from '@lib/shopify'
import { LineItem } from '..'
import { Button } from '@components/ui'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { formatPrice } from '@lib/pricing'
import { useRouter } from 'next/router'
import mixpanel from '@lib/mixpanel'

const goToCheckout: MouseEventHandler<HTMLButtonElement> = (e): void => {
  e.preventDefault()

  if (process.env.NODE_ENV === 'production') {
    mixpanel.track('Go to checkout')
  } else {
    console.info('Track "Go to checkout"')
  }

  // @ts-ignore
  window.open(e.target.href, '_self')
}

const Cart: FC = () => {
  const { t } = useTranslation(['common', 'cart'])
  const { locale = 'en-US' } = useRouter()

  const {
    isCartOpened,
    setIsCartOpened,
    cartItems,
    setCartItems,
    setTotalPrice,
    totalPrice,
    setCheckoutUrl,
    checkoutUrl,
  } = useCartContext()

  useMemo(() => {
    if (!process.browser) {
      return
    }

    async function getCart() {
      const cartId = window.localStorage.getItem(locale + '_shopify_cart_id')
      let cart = null

      if (cartId) {
        cart = await retrieveCart(cartId, locale)

        if (cart) {
          setCartItems(cart.lines.edges.map((e: any) => e.node))
          setCheckoutUrl(cart.checkoutUrl)
          setTotalPrice(cart.estimatedCost.totalAmount)
        } else {
          window.localStorage.removeItem(locale + '_shopify_cart_id')
        }
      }
    }

    getCart()
  }, [setCartItems, setTotalPrice, setCheckoutUrl, locale])

  return (
    <OffCanvas
      isOpen={isCartOpened}
      onClose={() => setIsCartOpened(false)}
      labelledby="bag-button"
      position="right"
      overlayClassName={s.cartOverlay}
      width="min(600px, 100%)"
      height="100%"
    >
      <section className={s.cartOffcanvas}>
        <header className="flex items-center gap-4 justify-between">
          <h1 className="text-xl md:text-3xl">{t('labels.shoppingCart')}</h1>
          <button className="text-base" onClick={() => setIsCartOpened(false)}>
            {t('labels.continueShopping')}
          </button>
        </header>
        <div className="flex flex-col flex-1 gap-4 overflow-hidden">
          {cartItems.length > 0 ? (
            <>
              <div className="flex-1 overflow-y-auto">
                <ul>
                  {cartItems.map((item) => (
                    <LineItem
                      item={item}
                      key={item.id}
                      className={s.lineItem}
                    />
                  ))}
                </ul>
              </div>
              <div className="flex justify-between gap-4 items-center">
                <h3 className="text-xl md:text-2xl">
                  {t('labels.totalPrice', {
                    price: t(`price.${totalPrice.currencyCode.toLowerCase()}`, {
                      price: formatPrice(
                        locale || 'en-US',
                        parseInt(totalPrice.amount, 10)
                      ),
                    }),
                  })}
                </h3>
                <Link href={checkoutUrl} passHref>
                  <Button Component="a" onClick={goToCheckout}>
                    {t('labels.finishOrder')}
                  </Button>
                </Link>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center text-center flex-col justify-center">
              <p className="text-xl">{t('cart:noItems')}</p>
              <Button
                variant="naked"
                className="mt-4"
                onClick={() => setIsCartOpened(false)}
              >
                <span className="text-black">
                  {t('labels.continueShopping')}
                </span>
              </Button>
            </div>
          )}
        </div>
      </section>
    </OffCanvas>
  )
}

export default Cart
