import { ShopifyCartLineItem, ShopifyTotalAmount } from '@types'
import {
  useContext,
  FC,
  createContext,
  useMemo,
  useReducer,
  useCallback,
} from 'react'

type State = {
  isCartOpened: Boolean
  items: ShopifyCartLineItem[]
  totalPrice: ShopifyTotalAmount
  checkoutUrl: string
}

type Action =
  | {
      type: 'SET_IS_CART_OPENED'
      isOpened: boolean
    }
  | {
      type: 'SET_CART_ITEMS'
      items: ShopifyCartLineItem[]
    }
  | {
      type: 'CLEAR_CART'
    }
  | {
      type: 'SET_TOTAL_PRICE'
      totalPrice: ShopifyTotalAmount
    }
  | {
    type: 'SET_CHECKOUT_URL',
    url: string
  }

type CartContextType = State & {
  addCartItem: (item: object) => void
  removeCartItem: (item: object) => void
  clearCart: () => void
  setIsCartOpened: (isOpened: boolean) => void
  setCartItems: (items: Array<object>) => void
  cartItems: ShopifyCartLineItem[]
  setTotalPrice: (totalPrice: object) => void
  totalPrice: object
  checkoutUrl: string
  setCheckoutUrl: (url: string) => void
}

const initialState: State = {
  isCartOpened: false,
  items: [],
  totalPrice: {
    amount: '0',
    currencyCode: 'czk',
  },
  checkoutUrl: ''
}

export const CartContext = createContext<State | any>(initialState)
CartContext.displayName = 'CartContext'

const cartReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_IS_CART_OPENED':
      return {
        ...state,
        isCartOpened: action.isOpened,
      }

    case 'SET_CART_ITEMS':
      return {
        ...state,
        items: action.items,
      }

    case 'CLEAR_CART':
      return {
        ...state,
        items: [],
      }

    case 'SET_TOTAL_PRICE':
      return {
        ...state,
        totalPrice: action.totalPrice,
      }

    case 'SET_CHECKOUT_URL':
      return {
        ...state,
        checkoutUrl: action.url
      }

    default:
      return state
  }
}

export const CartProvider: FC = (props) => {
  const [state, dispatch] = useReducer(cartReducer, initialState)

  const setCartItems = useCallback(
    (items: ShopifyCartLineItem[]) =>
      dispatch({ type: 'SET_CART_ITEMS', items }),
    [dispatch]
  )

  const addCartItem = useCallback(
    (item: ShopifyCartLineItem) => {
      const items: ShopifyCartLineItem[] = [...state.items]
      items.push(item)

      return dispatch({ type: 'SET_CART_ITEMS', items })
    },
    [dispatch, state.items]
  )

  const removeCartItem = useCallback(
    (item: ShopifyCartLineItem) => {
      const items = [...state.items]
      const index = items.findIndex((i: any) => i.id === item.id)

      if (index > -1) {
        items.splice(index, 1)
      }

      return dispatch({ type: 'SET_CART_ITEMS', items })
    },
    [dispatch, state.items]
  )

  const setIsCartOpened = useCallback(
    (isOpened: boolean) => dispatch({ type: 'SET_IS_CART_OPENED', isOpened }),
    [dispatch]
  )

  const clearCart = useCallback(
    () => dispatch({ type: 'CLEAR_CART' }),
    [dispatch]
  )

  const setTotalPrice = useCallback(
    (totalPrice: ShopifyTotalAmount) =>
      dispatch({ type: 'SET_TOTAL_PRICE', totalPrice }),
    [dispatch]
  )

  const setCheckoutUrl = useCallback((url: string): void => dispatch({ type: 'SET_CHECKOUT_URL', url }), [])

  const isCartOpened = useMemo(() => state.isCartOpened, [state.isCartOpened])
  const cartItems = useMemo(() => state.items, [state.items])
  const totalPrice = useMemo(() => state.totalPrice, [state.totalPrice])
  const checkoutUrl = useMemo(() => state.checkoutUrl, [state.checkoutUrl])

  const value = useMemo(
    () => ({
      isCartOpened,
      cartItems,
      totalPrice,
      checkoutUrl,
      addCartItem,
      removeCartItem,
      setIsCartOpened,
      clearCart,
      setCartItems,
      setTotalPrice,
      setCheckoutUrl
    }),
    [
      isCartOpened,
      cartItems,
      totalPrice,
      checkoutUrl,
      addCartItem,
      removeCartItem,
      setIsCartOpened,
      clearCart,
      setCartItems,
      setTotalPrice,
      setCheckoutUrl
    ]
  )

  return <CartContext.Provider value={value} {...props} />
}

export const useCartContext = () => {
  const context = useContext<CartContextType>(CartContext)

  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartProvider')
  }

  return context
}
