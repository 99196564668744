import Image from 'next/image'
import { FC, useState, useRef } from 'react'
import { ShopifyCartLineItem } from '@types'
import { Cross } from '@components/icons'
import Link from 'next/link'
import { removeCartLineItems, updateCartLineItem } from '@lib/shopify'
import cn from 'classnames'
import { useCartContext } from '../context'
import { Button } from '@components/ui'
import s from './LineItem.module.css'
import debounce from 'lodash.debounce'
import { useTranslation } from 'next-i18next'
import { formatPrice } from '@lib/pricing'
import { useRouter } from 'next/router'

type LineItemProps = {
  item: ShopifyCartLineItem
  className: string
}

const updateQuantityInShopify = debounce(
  async (
    item: ShopifyCartLineItem,
    quantity: Number,
    setCartItems: Function,
    setCheckoutUrl: Function,
    setTotalPrice: Function,
    setIsUpdatingQuantity: Function,
    locale: string
  ): Promise<void> => {
    setIsUpdatingQuantity(true)

    const cartId = window.localStorage.getItem(locale + '_shopify_cart_id')
    const res = await updateCartLineItem(
      cartId!,
      [{ id: item.id, quantity }],
      locale || 'en-US'
    )

    setCartItems(res.cart.lines.edges.map((e: { node: object }) => e.node))
    setCheckoutUrl(res.cart.checkoutUrl)
    setTotalPrice(res.cart.estimatedCost.totalAmount)
    setIsUpdatingQuantity(false)
  },
  1000
)

const LineItem: FC<LineItemProps> = ({ item, className }) => {
  const { t } = useTranslation(['cart', 'common'])
  const { locale = 'en-US' } = useRouter()
  const { setCartItems, setTotalPrice, setCheckoutUrl } = useCartContext()

  const [quantity, setQuantity] = useState(item.quantity)
  const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false)

  const removeItem = async (): Promise<void> => {
    const res = await removeCartLineItems(
      window?.localStorage.getItem(locale + '_shopify_cart_id') || '',
      [item.id],
      locale
    )

    setCartItems(res.cart.lines.edges.map((e: any) => e.node))
    setCheckoutUrl(res.cart.checkoutUrl)
    setTotalPrice(res.cart.estimatedCost.totalAmount)
  }

  const handleQuantityUpdate = (value: number): void => {
    if (value < 1) {
      setQuantity(1)
      updateQuantityInShopify(
        item,
        1,
        setCartItems,
        setCheckoutUrl,
        setTotalPrice,
        setIsUpdatingQuantity,
        locale
      )
    } else if (value > 99) {
      setQuantity(99)
      updateQuantityInShopify(
        item,
        99,
        setCartItems,
        setCheckoutUrl,
        setTotalPrice,
        setIsUpdatingQuantity,
        locale
      )
    } else {
      setQuantity(value)
      updateQuantityInShopify(
        item,
        value,
        setCartItems,
        setCheckoutUrl,
        setTotalPrice,
        setIsUpdatingQuantity,
        locale
      )
    }
  }

  return (
    <li className={cn([className, 'flex items-center gap-4 justify-between'])}>
      <Image
        src={item.merchandise.image.url!}
        alt={item.merchandise.image.altText || item.merchandise.product.title}
        width="50"
        height="50"
        className="rounded-sm"
      />
      <div className="flex-1">
        <h2 className="text-sm md:text-lg">
          <Link href={'/products/' + item.merchandise.product.handle}>
            <a>
              {item.merchandise.title.toLowerCase() !== 'default title'
                ? t('cart:productTitle', {
                    product: item.merchandise.product.title,
                    size: item.merchandise.title,
                  })
                : item.merchandise.product.title}
            </a>
          </Link>
        </h2>
        <div className="flex">
          <Button
            variant="naked"
            className={s.amountBtn}
            onClick={() => handleQuantityUpdate(quantity - 1)}
            disabled={isUpdatingQuantity}
          >
            -
          </Button>
          <input
            type="number"
            value={quantity}
            className={s.amountInput}
            onChange={(e) =>
              handleQuantityUpdate(
                e.target.value ? parseInt(e.target.value, 10) : 1
              )
            }
            min="1"
            max="99"
            disabled={!!isUpdatingQuantity}
          />
          <Button
            variant="naked"
            className={s.amountBtn}
            onClick={() => handleQuantityUpdate(quantity + 1)}
            disabled={isUpdatingQuantity}
          >
            +
          </Button>
        </div>
      </div>
      <div className="text-right">
        <button
          aria-label={t('common:labels.removeFromShoppingCart')}
          className="block ml-auto mb-1"
          onClick={removeItem}
        >
          <Cross />
        </button>
        {t(
          `common:price.${item.estimatedCost.totalAmount.currencyCode.toLowerCase()}`,
          {
            price: formatPrice(
              locale || 'en-US',
              parseInt(item.estimatedCost.totalAmount.amount)
            ),
          }
        )}
      </div>
    </li>
  )
}

export default LineItem
