/**
 * Formats given price into current locale string and adds two fraction digits
 * @param locale current locale
 * @param amount amount to be formatted
 * @returns formatted price
 */
export function formatPrice(locale: string, amount: Number): String {
  return amount.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}