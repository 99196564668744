import { FC, useState } from 'react'
import Link from 'next/link'
import OffCanvas from 'react-aria-offcanvas'

import { useCartContext } from '@components/cart/context'
import s from './Navbar.module.css'
import NavbarRoot from './NavbarRoot'
import { Logo, Container, Button } from '@components/ui'
import { Bag, Cross } from '@components/icons'
import Menu from '@components/icons/Menu'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'

const Navbar: FC = () => {
  const { t } = useTranslation('common')
  const { setIsCartOpened, cartItems } = useCartContext()
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  return (
    <NavbarRoot>
      <Container>
        <div className={s.nav}>
          <Link href="/">
            <a className={s.logo} aria-label="Logo">
              <Logo />
            </a>
          </Link>
          <nav className={s.navMenu}>
            <Link href="/products/men">
              <a className={s.link}>{t('common:categories.men')}</a>
            </Link>
            <Link href="/products/women">
              <a className={s.link}>{t('common:categories.women')}</a>
            </Link>
            <Link href="/products/accessories">
              <a className={s.link}>{t('common:categories.accessories')}</a>
            </Link>
            <Link href="/products/home-and-living">
              <a className={s.link}>{t('common:categories.home-and-living')}</a>
            </Link>
          </nav>
          <div className={s.actions}>
            <Button
              id="bag-button"
              variant="naked"
              className={s.actionsBtn}
              onClick={() => setIsCartOpened(true)}
            >
              <Bag className="text-black" />
              {cartItems.length > 0 && (
                <span className={s.cartItemsCount}>{cartItems.length}</span>
              )}
            </Button>
            <Button
              variant="naked"
              className={cn([s.actionsBtn, s.menuBtn])}
              aria-label={t('common:labels.openMenu')}
              onClick={() => setIsMenuOpened(true)}
            >
              <Menu className="text-black" />
            </Button>
          </div>
        </div>
      </Container>
      <OffCanvas
        isOpen={isMenuOpened}
        onClose={() => setIsMenuOpened(false)}
        width="100%"
        height="100%"
        overlayClassName="sm:hidden"
      >
        <section className="flex flex-col h-full sm:hidden p-8 bg-white">
          <div className="flex justify-between items-center">
            <Link href="/">
              <a className={s.logo} aria-label="Logo">
                <Logo />
              </a>
            </Link>
            <button onClick={() => setIsMenuOpened(false)}>
              <Cross />
            </button>
          </div>
          <nav className="text-center flex-1 flex items-center justify-center">
            <ul>
              <li>
                <Link href="/products/men">
                  <a className={s.link}>{t('common:categories.men')}</a>
                </Link>
              </li>
              <li className="mt-8">
                <Link href="/products/women">
                  <a className={s.link}>{t('common:categories.women')}</a>
                </Link>
              </li>
              <li className="mt-8">
                <Link href="/products/accessories">
                  <a className={s.link}>{t('common:categories.accessories')}</a>
                </Link>
              </li>
              <li className="mt-8">
                <Link href="/products/home-and-living">
                  <a className={s.link}>
                    {t('common:categories.home-and-living')}
                  </a>
                </Link>
              </li>
            </ul>
          </nav>
        </section>
      </OffCanvas>
    </NavbarRoot>
  )
}

export default Navbar
