import { FC } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { Logo, Container } from '@components/ui'
import s from './Footer.module.css'
import AmericanExpress from '@components/icons/AmericanExpress'
import ApplePay from '@components/icons/ApplePay'
import MasterCard from '@components/icons/MasterCard'
import Visa from '@components/icons/Visa'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

interface Props {
  className?: string
  children?: any
}

const Footer: FC<Props> = ({ className }) => {
  const { t } = useTranslation(['common'])
  const { locale } = useRouter()

  const rootClassName = cn(s.root, className)

  return (
    <footer className={rootClassName}>
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 border-b border-accent-2 py-12 text-primary bg-primary transition-colors duration-150">
          <div>
            <Link href="/">
              <a className={s.logo}>
                <Logo />
              </a>
            </Link>
            <p>{t('common:metaDescriptions.products')}</p>
          </div>
          <div>
            <h4 className="text-xl mb-5">{t('common:labels.categories')}</h4>
            <nav>
              <ul className="list-disc pl-4">
                <li className="mb-3">
                  <Link href="/products/men">{t('common:categories.men')}</Link>
                </li>
                <li className="mb-3">
                  <Link href="/products/women">
                    {t('common:categories.women')}
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="/products/accessories">
                    {t('common:categories.accessories')}
                  </Link>
                </li>
                <li>
                  <Link href="/products/home-and-living">
                    {t('common:categories.home-and-living')}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <h4 className="text-xl mb-5">{t('common:labels.usefulLinks')}</h4>
            <nav>
              <ul className="list-disc pl-4">
                <li className="mb-3">
                  <Link href="/terms-and-conditions">
                    {t('common:nav.termsAndConditions')}
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="/privacy-policy">
                    {t('common:nav.privacyPolicy')}
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="/cookies-policy">
                    {t('common:nav.cookiesPolicy')}
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="/refund-policy">
                    {t('common:nav.returnsPolicy')}
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="/size-chart">{t('common:nav.sizeChart')}</Link>
                </li>
                <li>
                  <Link href="/contact">{t('common:nav.contact')}</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <h4 className="text-xl mb-5">
              {t('common:footer.youCanFindMeAt')}
            </h4>
            <div className="flex items-center gap-3">
              <a
                href="https://www.facebook.com/enn.sens"
                target="_blank"
                rel="noreferrer"
              >
                FB
              </a>
              <div className={s.socialsDivider} />
              <a
                href="https://www.instagram.com/enn.sens"
                target="_blank"
                rel="noreferrer"
              >
                INS
              </a>
            </div>
          </div>
        </div>
        <div className="pt-6 pb-6 sm:pb-10 text-sm flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <p className="flex gap-2 items-center">
            {t('common:footer.paymentMethods')}
            <AmericanExpress />
            <ApplePay />
            <MasterCard />
            <Visa />
          </p>
          <p>{t('countries.' + (locale === 'cs-CZ' ? 'cs-CZ' : 'int'))}</p>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
