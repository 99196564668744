import {
  PathWithCategory,
  PathWithSlugAndLastModTime,
  ShopifyCollection,
  ShopifyProduct,
} from '@types'

type LineItem = {
  quantity: number
  merchandiseId: string
}

type CartInput = {
  lines: Array<LineItem>
  attributes?: object
}

function getDomain(locale: string): string | undefined {
  // if (locale === 'cs-CZ') {
  //   return process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_CS
  // }

  return process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN
}

function getAccessToken(locale: string): string | undefined {
  // if (locale === 'cs-CZ') {
  //   return process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESSTOKEN_CS
  // }

  return process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESSTOKEN
}

async function ShopifyData(query: string, locale: string, variables?: object) {
  const domain = getDomain(locale)
  const accessToken = getAccessToken(locale)

  if (!domain || !accessToken) {
    throw new Error('Shopify tokens not set')
  }

  const URL = `https://${domain}/api/2022-01/graphql.json`

  const options = {
    endpoint: URL,
    method: 'POST',
    headers: {
      'X-Shopify-Storefront-Access-Token': accessToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query, variables }),
  }

  try {
    const data = await fetch(URL, options).then((response) => {
      return response.json()
    })

    return data
  } catch (error) {
    console.error(error)
    throw new Error('Products not fetched')
  }
}

export async function getAllProducts(locale: string) {
  const query = `
  {
  products(first: 250) {
    edges {
      node {
        id
        title
        handle
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        handle
        images(first: 5) {
          edges {
            node {
              url
              altText
            }
          }
        }
        collections(first: 2) {
          edges {
            node {
              handle
            }
          }
        }
      }
    }
  }
}
`

  const response = await ShopifyData(query, locale)
  const allProducts = response.data.products.edges || []

  return allProducts
}

interface Product {
  node: ShopifyProduct
}

export async function getAllProductPaths(locale: string) {
  const query = `
  {
  products(first: 250) {
    edges {
      node {
        handle
        collections(first: 2) {
          edges {
            node {
              handle
              path: metafield(namespace: "nextjs", key: "path") {
                value
              }
            }
          }
        }
      }
    }
  }
}
`

  const response = await ShopifyData(query, locale)
  const allPaths =
    response.data.products.edges?.map((p: Product) => {
      const res: PathWithCategory = { slug: p.node.handle }

      if (p.node.collections.edges.length > 0) {
        const collections = p.node.collections.edges

        res.category =
          collections[0].node.handle === 'frontpage'
            ? collections[1].node.path.value
            : collections[0].node.path.value
      }

      return res
    }) || []

  return allPaths
}

export async function getSingleProduct(handle: string, locale: string) {
  const query = /* GraphQL */ `
    query getProductByHandle($handle: String!) {
      productByHandle(handle: $handle) {
        id
        handle
        title
        productType
        descriptionHtml
        description
        options {
          id
          name
          values
        }
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        variants(first: 250) {
          edges {
            node {
              id
              title
              sku
              availableForSale
              selectedOptions {
                name
                value
              }
              priceV2 {
                amount
                currencyCode
              }
              compareAtPriceV2 {
                amount
                currencyCode
              }
            }
          }
        }
        images(first: 250) {
          edges {
            node {
              url
              altText
            }
          }
        }
        metafield(namespace: "nextjs", key: "sizeChart") {
          key
          value
        }
        collections(first: 2) {
          edges {
            node {
              handle
            }
          }
        }
      }
    }
  `

  const {
    data: { productByHandle },
  } = await ShopifyData(query, locale, { handle })
  return productByHandle
}

export async function createCart(cartInput: CartInput, locale: string) {
  const query = /* GraphQL */ `
    mutation createCart($cartInput: CartInput) {
      cartCreate(input: $cartInput) {
        cart {
          id
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                estimatedCost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    title
                    image {
                      altText
                      url
                    }
                    product {
                      title
                      handle
                    }
                  }
                }
              }
            }
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          checkoutUrl
        }
      }
    }
  `

  try {
    const res = await ShopifyData(query, locale, { cartInput })
    window.localStorage.setItem(locale + '_shopify_cart_id', res.data.cartCreate.cart.id)

    return res.data.cartCreate
  } catch(error) {
    throw new Error(error)
  }
}

export async function addCartLineItems(
  cartId: string,
  lines: Array<LineItem>,
  locale: string
) {
  const query = /* GraphQL */ `
    mutation ($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          id
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                estimatedCost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    title
                    image {
                      altText
                      url
                    }
                    product {
                      title
                      handle
                    }
                  }
                }
              }
            }
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }
  `

  const res = await ShopifyData(query, locale, { cartId, lines })
  return res.data.cartLinesAdd
}

export async function addItemToCart(
  { quantity, merchandiseId }: LineItem,
  locale: string
) {
  const cartId = window.localStorage.getItem(locale + '_shopify_cart_id')

  if (cartId) {
    return addCartLineItems(cartId, [{ quantity, merchandiseId }], locale)
  }

  return createCart({ lines: [{ quantity, merchandiseId }] }, locale)
}

export async function retrieveCart(cartId: string, locale: string) {
  const query = /* GraphQL */ `
    query ($cartId: ID!) {
      cart(id: $cartId) {
        id
        lines(first: 10) {
          edges {
            node {
              id
              quantity
              estimatedCost {
                totalAmount {
                  amount
                  currencyCode
                }
              }
              merchandise {
                ... on ProductVariant {
                  title
                  image {
                    altText
                    url
                  }
                  product {
                    title
                    handle
                  }
                }
              }
            }
          }
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
        checkoutUrl
      }
    }
  `

  const res = await ShopifyData(query, locale, { cartId })
  return res.data.cart
}

export async function removeCartLineItems(
  cartId: string,
  lineIds: Array<string>,
  locale: string
) {
  const query = /* GraphQL */ `
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          id
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                estimatedCost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    title
                    image {
                      altText
                      url
                    }
                    product {
                      title
                      handle
                    }
                  }
                }
              }
            }
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }
  `

  const res = await ShopifyData(query, locale, { cartId, lineIds })
  return res.data.cartLinesRemove
}

export async function getProductsInCollection(
  handle: string,
  locale: string,
  limit: Number = 250
) {
  const query = /* GraphQL */ `
    query ($handle: String!, $limit: Int!) {
      collection(handle: $handle) {
        products(first: $limit) {
          edges {
            node {
              id
              handle
              title
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              images(first: 1) {
                edges {
                  node {
                    url
                    altText
                    width
                    height
                  }
                }
              }
              collections(first: 2) {
                edges {
                  node {
                    handle
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const res = await ShopifyData(query, locale, { handle, limit })
  return res.data.collection.products.edges
}

export async function updateCartLineItem(
  cartId: string,
  lines: object[],
  locale: string
) {
  const query = /* GraphQL */ `
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          id
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                estimatedCost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    title
                    image {
                      altText
                      url
                    }
                    product {
                      title
                      handle
                    }
                  }
                }
              }
            }
          }
          estimatedCost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }
  `

  try {
    const res = await ShopifyData(query, locale, { cartId, lines })
    console.log(res)
    return res.data.cartLinesUpdate
  } catch(error) {
    throw new Error(error)
  }
}

export async function getCollectionsPaths(locale: string) {
  const query = /* GraphQL */ `
    {
      collections(first: 250) {
        edges {
          node {
            handle
            path: metafield(namespace: "nextjs", key: "path") {
              value
            }
          }
        }
      }
    }
  `

  try {
    const res = await ShopifyData(query, locale)
    return res.data.collections.edges
      .map((e: { node: ShopifyCollection }): string | undefined => {
        if (e.node.path) {
          return e.node.path.value
        }
      })
      .filter(Boolean)
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCollections(locale: string) {
  const query = /* GraphQL */ `
    {
      collections(first: 250) {
        edges {
          node {
            handle
            title
            image {
              altText
              url
            }
            position: metafield(namespace: "nextjs", key: "position") {
              value
            }
            path: metafield(namespace: "nextjs", key: "path") {
              value
            }
          }
        }
      }
    }
  `

  try {
    const res = await ShopifyData(query, locale)
    return res.data.collections.edges
      .map((e: { node: ShopifyCollection }): ShopifyCollection => e.node)
      .filter((c: ShopifyCollection): Boolean => c.handle !== 'frontpage')
      .sort(
        (a: ShopifyCollection, b: ShopifyCollection) =>
          a.position.value - b.position.value
      )
  } catch (error) {
    throw new Error(error)
  }
}

export async function getProductPathsWithLastModTime(locale: string) {
  const query = /* GraphQL */ `
    {
      products(first: 250) {
        edges {
          node {
            handle
            updatedAt
            collections(first: 2) {
              edges {
                node {
                  handle
                  path: metafield(namespace: "nextjs", key: "path") {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  try {
    const response = await ShopifyData(query, locale)
    const allPaths =
      response.data.products.edges
        ?.map((p: Product): PathWithSlugAndLastModTime | null => {
          if (p.node.collections.edges.length < 1) {
            return null
          }

          return {
            slug: p.node.handle,
            updatedAt: p.node.updatedAt,
          }
        })
        .filter(Boolean) || []

    return allPaths
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCollectionPathsWithLastModTime(locale: string) {
  const query = /* GraphQL */ `
    {
      collections(first: 250) {
        edges {
          node {
            handle
            path: metafield(namespace: "nextjs", key: "path") {
              value
            }
            updatedAt
          }
        }
      }
    }
  `

  try {
    const res = await ShopifyData(query, locale)
    return res.data.collections.edges
      .map(
        (e: {
          node: ShopifyCollection
        }): PathWithSlugAndLastModTime | undefined => {
          if (e.node.path) {
            return {
              slug: e.node.path.value,
              updatedAt: e.node.updatedAt,
            }
          }
        }
      )
      .filter(Boolean)
  } catch (error) {
    throw new Error(error)
  }
}
